import React, { useMemo } from "react";
import Icon from "../../../components/Icons";
import IconMedia from "../../../components/Icons/IconMedia";
import { SortableHandle } from "react-sortable-hoc";

export default function MediaChannel({
  title,
  type,
  bgColor,
  onClick,
  onChangeInput,
  onChangeInputTitle,
  sortChannels,
  placeholder,
  item,
  style,
}) {
  const value = item.value;
  const titleOfChannel = item.title;
  const name = item.name;
  const DragHandle = useMemo(
    () => SortableHandle(({ value }) => <span>{value}</span>),
    []
  );

  const htmlType = (name) => {
    let title = "";
    if (name === "phone") {
      title = "Số điện thoại";
    } else if (name === "website") {
      title = "Đường link website kèm https:// ở đầu";
    } else {
      title = "Nội dung";
    }
    return (
      <>
        <input
          value={titleOfChannel}
          onChange={(e) => onChangeInputTitle(e.target.value)}
          className="flex-auto w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none mb-4"
          placeholder={"Tên hiển thị"}
        />
        <input
          value={value}
          onChange={(e) => onChangeInput(e.target.value)}
          className="flex-auto w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          placeholder={title}
        />
      </>
    );
  };

  return (
    <>
      <div className="flex justify-between text-white text-md font-bold mb-2 relative">
        {title}
        <span>
          {!sortChannels ? (
            <button
              className="focus:outline-none"
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <Icon
                className="rounded-full text-white hover:text-red-700"
                type="times"
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </button>
          ) : null}
        </span>
      </div>

      {name === "custom" || name === "phone" || name === "website" ? (
        <div className="w-full">
          {sortChannels ? (
            <>
              <div className="bg-white flex-auto shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none mb-4">
                {titleOfChannel ? (
                  titleOfChannel
                ) : (
                  <span className="invisible">null value</span>
                )}
              </div>
              <div className="bg-white flex-auto shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none">
                {value ? value : <span className="invisible">null value</span>}
              </div>
            </>
          ) : (
            htmlType(name)
          )}
        </div>
      ) : (
        <div className="flex flex-wrap w-full mb-4 relative">
          <div className="flex">
            <span className="flex items-center rounded rounded-r-none px-1">
              <DragHandle
                value={
                  <IconMedia type={type} bgColor={bgColor} style={style} />
                }
              />
            </span>
          </div>
          {sortChannels ? (
            <div className="bg-white flex-auto shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none">
              {value}
            </div>
          ) : (
            <input
              value={value}
              onChange={(e) => onChangeInput(e.target.value)}
              className="flex-auto shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              placeholder={placeholder}
            />
          )}
        </div>
      )}
    </>
  );
}
