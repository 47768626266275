import React from "react";
import Icon from ".";

export default function IconMedia({
  type,
  bgColor,
  height = 38,
  width = 38,
  style = {},
}) {
  console.log(style);
  return (
    <div
      className="rounded-full flex justify-center items-center"
      style={{
        color: "#fff",
        height,
        width,
        backgroundColor: bgColor,
      }}
    >
      <Icon
        type={type}
        style={{
          height: height * 0.7,
          width: width * 0.7,
          ...style,
        }}
      />
    </div>
  );
}
