import React, { useState, useEffect } from "react";
import { Redirect, Link } from "@reach/router";
import ReCAPTCHA from "react-google-recaptcha";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useInjectSaga } from "../../../utils/injectSaga";
import { useInjectReducer } from "../../../utils/injectReducer";
import { makeSelectIsLogin, makeSelectError } from "../selectors";
import { createAccount, setError } from "../actions";
import saga from "../saga";
import reducer from "../reducer";

import Icon from "../../../components/Icons";
const key = "auth";

const errorCheckPoint = {
  1: "Số điện thoại không khớp, vui lòng nhắn tin vào facebook page để được hỗ trợ!",
  2: "Vui lòng nhập số điện thoại bạn dùng khi đặt order!",
};
const errorCheckPointCode = ["2", "1"];

function SignUp({ isLogin, signUp, error, setError }) {
  let captcha;
  const [registerForm, setregisterForm] = useState({
    username: "",
    password: "",
    email: "",
    fullName: "",
    serialNumber: "",
    recaptcha: null,
    phone: "",
  });

  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  useEffect(() => {
    return () => {
      setError(null);
    };
  }, [setError]);

  if (isLogin) {
    return <Redirect to="/profile" noThrow />;
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onChangeRecaptcha = (value) => {
    setregisterForm({ ...registerForm, recaptcha: value });
  };
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };

  const onSignUp = (e) => {
    e.preventDefault();

    if (
      registerForm.password &&
      registerForm.username &&
      registerForm.serialNumber &&
      registerForm.email
    ) {
      if (!registerForm.username.match(/^[a-z0-9]+$/i)) {
        setError("Vui lòng nhập tên đăng nhập chính xác");
        return;
      }
      if (!validateEmail(registerForm.email)) {
        setError("Vui lòng nhập email chính xác");
        return;
      }
      signUp({
        ...registerForm,
        username: registerForm.username.toLowerCase(),
        email: registerForm.email.toLowerCase(),
      });
    } else {
      setError("Vui lòng nhập đủ thông tin");
    }

    resetCaptcha();
  };

  return (
    <div
      className="min-h-screen flex justify-center items-center p-4"
      style={{ backgroundColor: "#181818" }}
    >
      <div className="w-full max-w-md">
        <form
          className="bg-white shadow-md rounded p-8 mb-4"
          style={{ backgroundColor: "#232323" }}
          onSubmit={onSignUp}
        >
          <Icon
            type="avatar"
            style={{ height: 100, width: "auto", margin: "0 auto" }}
          />
          <h2 className="font-bold text-4xl text-center py-4 text-white">
            Đăng Kí
          </h2>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="fullName"
            >
              Họ tên của bạn
            </label>
            <input
              value={registerForm.fullName}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="fullName"
              type="text"
              placeholder="Họ tên của bạn"
              onChange={(e) =>
                setregisterForm({ ...registerForm, fullName: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="username"
            >
              Tên đăng nhập{" "}
              <span className="italic bold text-xs text-red-500">
                (chỉ nhập chữ và số)
              </span>
            </label>
            <input
              value={registerForm.username}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="username"
              type="text"
              placeholder="Tên đăng nhập"
              onChange={(e) =>
                setregisterForm({ ...registerForm, username: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              value={registerForm.email}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="email"
              type="text"
              placeholder="Email"
              onChange={(e) =>
                setregisterForm({ ...registerForm, email: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="serialNumber"
            >
              Mã metap
            </label>
            <input
              value={registerForm.serialNumber}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="serialNumber"
              type="text"
              placeholder="Nhập mã metap được gửi kèm thẻ"
              onChange={(e) =>
                setregisterForm({
                  ...registerForm,
                  serialNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-1">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mật khẩu
            </label>
            <input
              value={registerForm.password}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none"
              id="password"
              type="password"
              placeholder="****"
              onChange={(e) =>
                setregisterForm({ ...registerForm, password: e.target.value })
              }
            />
          </div>

          {errorCheckPointCode.includes(error) ? (
            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="serialNumber"
              >
                Số điện thoại bạn dùng khi đặt order
              </label>
              <input
                value={registerForm.phone}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                id="phone"
                type="text"
                placeholder="Nhập số điện thoại bạn dùng khi đặt order!"
                onChange={(e) =>
                  setregisterForm({
                    ...registerForm,
                    phone: e.target.value,
                  })
                }
              />
            </div>
          ) : null}

          <div className="mt-4">
            <ReCAPTCHA
              ref={(r) => setCaptchaRef(r)}
              sitekey="6Lda7HEbAAAAAOpYDu-SlUc1x4jynZPNVYugpLEU"
              onChange={onChangeRecaptcha}
              theme="dark"
            />
          </div>
          <div className="text-center mt-2">
            <p className="text-red-500 text-xs italic" style={{ height: 18 }}>
              {error && errorCheckPoint[error] ? errorCheckPoint[error] : ""}
              {error && !errorCheckPoint[error] ? error : ""}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="btn-primary font-bold py-2 px-4 rounded focus:outline-none"
              type="submit"
              style={{ minWidth: 100 }}
            >
              Đăng kí
            </button>
            <Link
              className="inline-block align-baseline font-bold text-sm"
              to="/login"
            >
              Bấm vào đây <span className="text-white">để đăng nhập</span>
            </Link>
          </div>
        </form>
        <p className="text-center text-white text-xs">
          &copy;2020 metap.vn - All rights reserved.
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  isLogin: makeSelectIsLogin(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (data) => dispatch(createAccount(data)),
    setError: (data) => dispatch(setError(data)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SignUp);
