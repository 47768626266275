import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { coreAPI } from "../../utils/request";
import { navigate, Link } from "@reach/router";
import Icon from "../../components/Icons";
import { channelMediaListDefault } from "../Dashboard";
import IconMedia from "../../components/Icons/IconMedia";
import logoMetap from "../../static/images/logo.jpg";

const STATIC_IMAGES = process.env.REACT_APP_CORE_API + "images/thumb-";

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;
export default function ViewProfile({ location }) {
  const [profile, setprofile] = useState(null);
  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const username = location?.pathname.slice(1, location?.pathname.lennth);
    try {
      const { data } = await coreAPI.get("/profile/" + username);
      setprofile(data);
    } catch (error) {
      console.log(error);
      navigate("/404");
    }
  };

  if (profile === null) {
    return (
      <div
        className="min-h-screen p-4 text-white"
        style={{ backgroundColor: "#181818" }}
      />
    );
  }

  console.log(profile);

  const { images, fullName, bio, channels } = profile;

  return (
    <div
      className="min-h-screen text-white"
      style={{ backgroundColor: "#181818" }}
    >
      <Container>
        <div className="flex p-4 ">
          <div>
            {images ? (
              <img
                className="rounded-full md:w-40 w-32 md:h-40 h-32 object-cover"
                src={STATIC_IMAGES + images}
                alt="logo"
              />
            ) : (
              <Icon type="avatar" style={{ height: 160, width: "auto" }} />
            )}
          </div>
          <div className="md:pl-8 pl-4 md:pt-8 pt-1">
            <div
              className="text-3xl font-bold"
              style={{ wordBreak: "break-word" }}
            >
              {fullName ? fullName : <>&nbsp;</>}
            </div>
            <div style={{ wordBreak: "break-word" }}>{bio}</div>
          </div>
        </div>
      </Container>

      <div>
        {channels.map((i, idx) => (
          <a
            target="_blank"
            href={i.value}
            key={idx}
            className="px-5 py-4 border-t-3 border-black block text-white"
            style={{ minHeight: 68 }}
          >
            <Container className="flex items-center">
              <IconMedia
                height={70}
                width={70}
                type={channelMediaListDefault[i.name].type}
                bgColor={channelMediaListDefault[i.name].bgColor}
              />
              <div className="pl-3">
                <div className="font-bold text-xl">
                  {channelMediaListDefault[i.name].title}
                </div>
                {/* <div>{channels[idx].value}</div> */}
              </div>
            </Container>
          </a>
        ))}
      </div>

      <div className="pt-8 pb-1 px-4">
        <div className="flex justify-center">
          {/* <img
            className=""
            src={logoMetap}
            style={{ width: 205, maxHeight: 70 }}
            alt="logo"
          /> */}
          <div
            className="font-bold text-5xl text-white px-8 "
            style={{ letterSpacing: -5, lineHeight: 1 }}
          >
            metap
          </div>
        </div>
        <div className="text-center py-2 px-2 font-semibold text-sm">
          Thẻ thông minh{" "}
          <Link className="" style={{ color: "#38d39f" }} to="/">
            Metap
          </Link>
        </div>
        <div className="flex justify-center">
          <div className="text-sm text-center" style={{ maxWidth: 400 }}>
            Thẻ cá nhân thông minh đầu tiên tại việt nam
          </div>
        </div>
      </div>
    </div>
  );
}
