import React from "react";
import { Router as ReachRouter } from "@reach/router";

import Test from "./containers/Test";
import Login from "./containers/Auth/Form/Login";
import SignUp from "./containers/Auth/Form/SignUp";
import Dashboard from "./containers/Dashboard";
import ViewProfile from "./containers/ViewProfile";
import NotFoundPage from "./components/NotFoundPage";
import ForgotPassword from "./containers/Auth/Form/ForgotPassword";
import ResetPassword from "./containers/Auth/Form/ResetPassword";

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => {
    document.body.style.overflow = "visible";
    return window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
export default function Router() {
  return (
    <ReachRouter primary={false}>
      <ScrollToTop path="/">
        <SignUp path="/" />
        <Login path="/login" />
        <Dashboard path="/profile" />

        <ForgotPassword path="/forgot-password" />
        <ResetPassword path="/reset-password/:id" />
        <ViewProfile path="/:id" />
        <Test path="/test" />
        <NotFoundPage path="/404" />
        <NotFoundPage path="*" />
      </ScrollToTop>
    </ReachRouter>
  );
}
