import React, { useState, useEffect } from "react";
import { Link, Redirect } from "@reach/router";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useInjectSaga } from "../../../utils/injectSaga";
import { useInjectReducer } from "../../../utils/injectReducer";
import {
  makeSelectIsLogin,
  makeSelectError,
  makeSelectIsLoading,
} from "../selectors";
import { logIn, setError } from "../actions";
import saga from "../saga";
import reducer from "../reducer";

import Icon from "../../../components/Icons";
const key = "auth";

function LogIn({ isLogin, loading, logIn, error, setError }) {
  const [formLogin, setformLogin] = useState({ username: "", password: "" });
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  useEffect(() => {
    return () => {
      setError(null);
    };
  }, [setError]);

  if (isLogin) {
    return <Redirect to="/profile" noThrow />;
  }

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      if (formLogin.password && formLogin.username) {
        await logIn({
          ...formLogin,
          username: formLogin.username.toLowerCase(),
        });
      } else {
        setError("Vui lòng nhập đủ thông tin");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="min-h-screen flex justify-center items-center p-4"
      style={{ backgroundColor: "#181818" }}
    >
      <div className="w-full max-w-md">
        <form
          className="bg-white shadow-md rounded p-8 mb-4"
          style={
            loading
              ? { backgroundColor: "rgba(255,255,255,0.3)" }
              : { backgroundColor: "#232323" }
          }
          onSubmit={onLogin}
        >
          {loading && <div className="loader absolute"></div>}
          <Icon
            type="avatar"
            style={{ height: 100, width: "auto", margin: "0 auto" }}
          />
          <h2 className="font-bold text-4xl text-center py-4 text-white">
            Đăng Nhập
          </h2>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="username"
            >
              Tên đăng nhập hoặc email
            </label>
            <input
              value={formLogin.username}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="username"
              type="text"
              placeholder="Tên đăng nhập hoặc email"
              onChange={(e) =>
                setformLogin({ ...formLogin, username: e.target.value })
              }
            />
          </div>
          <div className="mb-1">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mật khẩu
            </label>
            <input
              value={formLogin.password}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none"
              id="password"
              type="password"
              placeholder="****"
              onChange={(e) =>
                setformLogin({ ...formLogin, password: e.target.value })
              }
            />
            <p className="text-red-500 text-xs italic" style={{ height: 18 }}>
              {error ? "Sai tên đăng nhập hoặc mật khẩu" : ""}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="btn-primary font-bold py-2 px-4 rounded focus:outline-none"
              type="submit"
              style={{ minWidth: 100 }}
            >
              Đăng nhập
            </button>
            <Link
              className="inline-block align-baseline font-bold text-sm"
              to="/forgot-password"
            >
              Quên mật khẩu?
            </Link>
          </div>
        </form>
        <p className="text-center text-white text-xs">
          &copy;2020 metap.vn - All rights reserved.
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  isLogin: makeSelectIsLogin(),
  loading: makeSelectIsLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (data) => dispatch(logIn(data)),
    setError: (data) => dispatch(setError(data)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(LogIn);
