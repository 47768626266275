import React, { useState, useRef, useEffect } from "react";
import Icon from "../../components/Icons";

export default function DropDown({
  value,
  title,
  isMulti = false,
  options,
  onChange,
  onClick,
  style,
}) {
  const inputEl = useRef(null);
  const [show, setshow] = useState(false);
  const ButtonOption = ({ children, active, ...props }) => (
    <div
      {...props}
      className={`cursor-pointer relative w-full text-left text-gray-700 block px-3 py-2 text-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 ${
        active ? "bg-gray-100" : ""
      }`}
    >
      {children}
      {active ? (
        <i className="absolute" style={{ right: 15 }}>
          <Icon type="tick" />
        </i>
      ) : null}
    </div>
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickShow);
    return () => {
      document.removeEventListener("mousedown", handleClickShow);
    };
  }, []);

  const handleClickShow = (e) => {
    if (inputEl.current.contains(e.target)) {
      setshow(true);
      return;
    }
    setshow(false);
  };

  const onClickDropdown = (option) => {
    if (isMulti) {
      if (activeOption(option)) {
        onChange(value.filter((f) => f.value !== option.value));
      } else if (onChange) {
        onChange([...value, option]);
      }
    } else {
      if (onChange) {
        onChange(option);
      }
      if (onClick) {
        onClick(option);
      }
      setshow(false);
    }
  };

  let labelDropdown = title;
  if (value) {
    if (isMulti) {
      if (value.length > 0) {
        labelDropdown = value?.map((i) => i.name).join(", ");
      }
    } else {
      labelDropdown = value?.name;
    }
  }

  const activeOption = (option) => {
    if (isMulti) {
      return !!value.find((i) => i.value === option.value);
    }

    return value && value.value === option.value ? true : false;
  };

  return (
    <div className="relative w-full mt-2" ref={inputEl}>
      <div>
        <span className="rounded">
          <button
            style={style}
            style={{ maxHeight: 38, ...style }}
            onClick={handleClickShow}
            type="button"
            className="text-left text-gray-700 w-full rounded border border-gray-500 px-3 py-2 focus:outline-none focus:bg-primary bg-white"
          >
            <div>{labelDropdown}</div>
            <i className="absolute" style={{ right: 15, top: 10 }}>
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </i>
          </button>
        </span>
      </div>

      <div
        className={`origin-top-right w-full absolute right-0 mt-2 w-56 rounded shadow-lg z-10 ${
          show ? "block" : "hidden"
        }`}
      >
        <div
          className="rounded bg-white shadow-xs"
          style={{ overflow: "auto", maxHeight: 200 }}
        >
          <div className="py-1">
            {options?.map((option, idx) => (
              <ButtonOption
                key={idx}
                active={activeOption(option)}
                onClick={(e) => {
                  e.preventDefault();
                  onClickDropdown(option);
                }}
              >
                {option?.name}
              </ButtonOption>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
