import React from "react";
import Icon from "../../../components/Icons";
import { channelMediaListDefault } from "..";
import IconMedia from "../../../components/Icons/IconMedia";
const STATIC_IMAGES = process.env.REACT_APP_CORE_API + "images/thumb-";

export default function Preview({ images, fullName, bio, mediaChannels }) {
  return (
    <div>
      <div className="flex">
        <div className="p-3 pt-5">
          {images ? (
            <img
              className="rounded-full object-cover md:w-20 w-16 md:h-20 h-16"
              src={
                images?.change
                  ? images?.preview
                  : STATIC_IMAGES + images?.preview
              }
              alt="logo"
            />
          ) : (
            <Icon type="avatar" style={{ height: 80, width: "auto" }} />
          )}
        </div>
        <div className="px-2 pt-4 ">
          <div
            className="text-lg font-bold"
            style={{ wordBreak: "break-word" }}
          >
            {fullName ? fullName : <>&nbsp;</>}
          </div>
          <div className="text-sm" style={{ wordBreak: "break-word" }}>
            {bio}
          </div>
        </div>
      </div>

      <div>
        {mediaChannels.map((i, idx) => (
          <div
            key={idx}
            className="flex px-5 py-2 items-center border-t-3 border-black"
            style={{ minHeight: 68 }}
          >
            <div style={{ width: 38 }}>
              <IconMedia
                type={channelMediaListDefault[i.name]?.type}
                bgColor={channelMediaListDefault[i.name]?.bgColor}
              />
            </div>
            <div className="pl-3">
              <div className="font-bold">
                {i.title ? i.title : channelMediaListDefault[i.name]?.title}
              </div>
              <div className="break-all">{mediaChannels[idx]?.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
