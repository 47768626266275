import React, { useEffect, useRef, useState, useMemo } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Redirect, Link } from "@reach/router";
import styled from "styled-components";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import ArrayMove from "array-move";

import DropDown from "../../components/Dropdown";
import { useInjectSaga } from "../../utils/injectSaga";
import { useInjectReducer } from "../../utils/injectReducer";

import { logOut, fetchUser } from "../Auth/actions";
import { makeSelectIsLogin, makeSelectUser } from "../Auth/selectors";
import logoMetap from "../../static/images/logo.jpg";
import instructionMetap from "../../static/huongdan.pdf";

import saga from "../Auth/saga";
import reducer from "../Auth/reducer";
import MediaChannel from "./components/MediaChannel";
import Preview from "./components/Preview";
import { getBase64 } from "../../utils/file";
import { coreAPI } from "../../utils/request";
import { toast } from "react-toastify";
import moment from "moment";
const key = "auth";
const Container = styled.div`
  width: 100%;
  max-width: 996px;
  margin-left: auto;
  margin-right: auto;
`;
const notifySuccess = (msg) =>
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = (msg) =>
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const channelMediaListDefault = {
  facebook: {
    title: "Facebook",
    placeholder: "Username facebook, ví dụ dunglailaptrinh",
    type: "facebook-f",
    bgColor: "#1d4b99",
  },
  instagram: {
    title: "Instagram",
    placeholder: "Username Instagram, ví dụ dunglailaptrinh",
    type: "instagram",
    bgColor: "#e1306c",
  },
  whatsapp: {
    title: "whatsapp",
    placeholder: "Số điện thoại whatsapp kèm mã vùng và dấu +",
    type: "whatsapp",
    bgColor: "#37b726",
  },
  zalo: {
    title: "Zalo",
    placeholder: "Username Zalo, ví dụ dunglailaptrinh",
    type: "zalo",
    bgColor: "#fff",
  },
  youtube: {
    title: "Youtube",
    placeholder: "Channel Id Youtube, ví dụ UCMYT8xymrm4VOP241b86MCQ",
    type: "youtube",
    bgColor: "red",
  },
  email: {
    title: "Email",
    placeholder: "Email",
    type: "envelope",
    bgColor: "#007eff",
  },
  phone: {
    title: "Phone",
    placeholder: "Số điện thoại",
    type: "phone",
    bgColor: "#000000",
  },
  linkedin: {
    title: "Linkedin",
    placeholder: "Username Linkedin ví dụ dunglailaptrinh",
    type: "linkedinIn",
    bgColor: "#0275b4",
  },
  tiktok: {
    title: "Tiktok",
    placeholder: "Username Tiktok, ví dụ dunglailaptrinh",
    type: "tiktokColor",
    bgColor: "#000",
  },
  snapchat: {
    title: "Snapchat",
    placeholder: "Username Snapchat, ví dụ dunglailaptrinh",
    type: "snapchat",
    bgColor: "rgb(248 245 0)",
  },
  soundcloud: {
    title: "Soundcloud",
    placeholder: "Username Soundcloud, ví dụ dunglailaptrinh",
    type: "soundcloud",
    bgColor: "rgb(248 83 0)",
  },
  twitter: {
    title: "Twitter",
    placeholder: "Username Twitter, ví dụ dunglailaptrinh",
    type: "twitter",
    bgColor: "#1c9deb",
  },
  spotify: {
    title: "Spotify",
    placeholder: "Username Spotify",
    type: "spotify",
    bgColor: "#2ebd59",
  },
  vimeo: {
    title: "Vimeo",
    placeholder: "Username Vimeo",
    type: "vimeo",
    bgColor: "#00a8e8",
  },
  github: {
    title: "Github",
    placeholder: "Username Github",
    type: "github",
    bgColor: "black",
  },
  telegram: {
    title: "Telegram",
    placeholder: "Username Telegram",
    type: "telegram",
    bgColor: "#28a3e4",
  },
  flickr: {
    title: "Flickr",
    placeholder: "Username Flickr",
    type: "flickr",
    bgColor: "white",
  },
  momo: {
    title: "Ví Momo",
    placeholder: "Số điện thoại momo, ví dụ 0888421173",
    type: "momo",
    bgColor: "#a50064",
    style: { padding: 2 },
  },
  website: {
    title: "Website tùy chọn",
    placeholder: "URL Website kèm http:// ở đầu, ví dụ https://metap.vn",
    type: "link",
    bgColor: "#000000",
  },
  custom: {
    title: "Ghi chú riêng",
    placeholder: "Tiêu đề",
    type: "sticky-note",
    bgColor: "#000000",
  },
};

const Input = ({ title, id, ...props }) => (
  <>
    <label className="block text-white text-md font-bold mb-2" htmlFor={id}>
      {title}
    </label>
    <input
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
      id={id}
      type="text"
      {...props}
    />
  </>
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const SortableItem = sortableElement(
  ({ sortChannels, value, idx, mediaChannelsItem }) => {
    return (
      <li className="border border-dashed py-1 mb-5 cursor-move">
        <div className="p-1 px-2">
          <MediaChannel
            {...channelMediaListDefault[value.name]}
            item={mediaChannelsItem[idx]}
            sortChannels={sortChannels}
          />
        </div>
      </li>
    );
  }
);
const nowDay = new Date();
// const dobDefault = {
//   day: { value: nowDay.getDate(), name: nowDay.getDate() },
//   month: { value: nowDay.getMonth() + 1, name: nowDay.getMonth() + 1 },
//   year: { value: nowDay.getFullYear(), name: nowDay.getFullYear() },
// };
const dobDefault = {
  day: { value: 0, name: 0 },
  month: { value: 0, name: 0 },
  year: { value: 0, name: 0 },
};

function getLastDayOfMonth(year, month) {
  let date = new Date(year, month, 0);
  return date.getDate();
}

const listMonth = [];
for (let index = 0; index < 12; index++) {
  listMonth.push({ value: index + 1, name: index + 1 });
}
const listYear = [];
for (let index = 0; index < 60; index++) {
  listYear.push({
    value: nowDay.getFullYear() - index,
    name: nowDay.getFullYear() - index,
  });
}

function Dashboard({
  isLogin,
  logOut,
  children,
  fetchUser,
  currentUser,
  title,
}) {
  const [loading, setloading] = useState(false);
  const inputUploadImage = useRef(null);
  const [sortChannels, setsortChannels] = useState(false);
  const [mediaCur, setmediaCur] = useState(null);
  const [mediaChannels, setmediaChannels] = useState([]);
  const [fullName, setfullName] = useState("");
  const [bio, setbio] = useState("");
  const [images, setimages] = useState(null);
  const [dob, setdob] = useState({ ...dobDefault });
  const [company, setcompany] = useState("");

  useInjectSaga({ key: key, saga });
  useInjectReducer({ key: key, reducer });
  useEffect(() => {
    if (!isLogin) {
      return;
    }
    if (currentUser === null) {
      fetchUser();
    }
    //eslint-disable-next-line
  }, [currentUser, isLogin]);

  const reset = () => {
    setsortChannels(false);
    setmediaCur(null);
    setmediaChannels([]);
    setfullName("");
    setbio("");
    setimages(null);
    setdob({ ...dobDefault });
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    setloading(true);
    if (currentUser) {
      setfullName(currentUser.fullName);
      setbio(currentUser.bio);
      if (currentUser.images) {
        setimages({ preview: currentUser.images, change: false });
      }

      setmediaChannels(currentUser.channels);
      setcompany(currentUser.company);
      if (currentUser?.dob?.day) {
        setdob({
          day: { value: currentUser?.dob?.day, name: currentUser?.dob?.day },
          month: {
            value: currentUser?.dob?.month,
            name: currentUser?.dob?.month,
          },
          year: { value: currentUser?.dob?.year, name: currentUser?.dob?.year },
        });
      }
    }
    setloading(false);
  }, [currentUser]);

  if (!isLogin) {
    return <Redirect to="/" noThrow />;
  }

  const onUpdate = async () => {
    console.log(
      new Date(
        String(dob.month.value) +
          "/" +
          String(dob.day.value) +
          "/" +
          String(dob.year.value)
      )
    );
    // if (
    //   new Date(
    //     String(dob.month.value) +
    //       "/" +
    //       String(dob.day.value) +
    //       "/" +
    //       String(dob.year.value)
    //   )
    // ) {
    //   notifyError("Ngày sinh không hợp lệ");
    //   return;
    // }
    try {
      const params = {};
      if (images) {
        let photo = images.preview;

        if (images.change) {
          const formData = new FormData();
          formData.append("files", images.file);
          const imagesres = await coreAPI.post(`/upload/images`, formData);
          photo = imagesres[0];
        }
        params.images = photo;
      }
      params.fullName = fullName;
      params.bio = bio;
      params.channels = mediaChannels;
      params.company = company;
      params.dob = {
        day: dob.day.value,
        month: dob.month.value,
        year: dob.year.value,
      };
      const { data } = await coreAPI.put(`user/edit`, params);
      notifySuccess("Updated successfull");
    } catch (error) {
      console.log(error);
    }
  };
  const onRemove = (idx) => {
    const mediaTmp = [...mediaChannels];
    mediaTmp.splice(idx, 1);
    setmediaChannels(mediaTmp);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setmediaChannels(ArrayMove(mediaChannels, oldIndex, newIndex));
  };

  const handleUpload = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file);
    }

    setimages({
      preview: file.url || file.preview,
      file: file,
      change: true,
    });
  };

  const listDay = [];
  if (dob.month.value) {
    const numDayOfMonth = getLastDayOfMonth(dob.year.value, dob.month.value);
    for (let index = 0; index < numDayOfMonth; index++) {
      listDay.push({ value: index + 1, name: index + 1 });
    }
  } else {
    for (let index = 0; index < 31; index++) {
      listDay.push({ value: index + 1, name: index + 1 });
    }
  }

  return (
    <div
      className="min-h-screen p-4 text-white"
      style={{ backgroundColor: "#181818" }}
    >
      <Container>
        <div className="relative">
          <div className="pt-5 pb-1">
            <div
              className="font-bold text-6xl text-white px-8 text-center"
              style={{ letterSpacing: -5, lineHeight: 1 }}
            >
              metap
            </div>
            {/* <img className="m-auto" src={logoMetap} width={205} alt="logo" /> */}
          </div>
          <div className="text-center text-xl pb-2">Đổi thông tin</div>
          <div className="text-center text-sm pb-6">
            Lưu ý: Mọi thông tin bạn đưa vào trang cá nhân metap đều trở thành
            thông tin public và mọi người có thể tìm thấy được.
          </div>
          <button
            onClick={logOut}
            className="absolute focus:outline-none text-primary font-bold"
            style={{ top: 0, right: 0 }}
          >
            Đăng xuất
          </button>
        </div>
        {loading && (
          <div className="loading-data">
            <div className="loader" />
          </div>
        )}
        <div className="flex flex-wrap -mx-2">
          <div className="md:w-1/2 w-full px-2">
            {/* <form className="" onSubmit={onUpdate}> */}
            <div className="">
              <div className="mb-4">
                <Input
                  title="Tên hiển thị"
                  id="username"
                  value={fullName}
                  onChange={(e) => setfullName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-white text-md font-bold mb-2"
                  htmlFor="upload-image"
                >
                  Hình đại diện
                </label>
                <button
                  onClick={(e) => {
                    inputUploadImage.current.click();
                  }}
                  style={{ maxHeight: 38 }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 focus:outline-none bg-white text-gray font-semibold"
                >
                  <input
                    id="upload-image"
                    className=" hidden"
                    accept="image/*"
                    type="file"
                    ref={inputUploadImage}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        handleUpload(e.target.files[0]);
                      }
                    }}
                  />
                  Bấm để thay hình đại diện
                </button>
              </div>
              <div className="mb-4">
                <Input
                  title="Lời giới thiệu"
                  id="bio"
                  value={bio}
                  onChange={(e) => setbio(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <Input
                  title="Công ty"
                  id="company"
                  value={company}
                  onChange={(e) => setcompany(e.target.value)}
                />
              </div>

              <div>
                <label className="block text-white text-md font-bold">
                  Ngày sinh
                </label>
              </div>
              <div className="flex flex-wrap -mx-2 mb-4">
                <div className="md:w-1/3 w-full px-2">
                  <DropDown
                    value={dob.day}
                    onChange={(value) => {
                      setdob({ ...dob, day: value });
                    }}
                    title="Ngày"
                    options={listDay}
                  />
                </div>
                <div className="md:w-1/3 w-full px-2">
                  <DropDown
                    value={dob.month}
                    onChange={(value) => {
                      setdob({ ...dob, month: value });
                    }}
                    title="Tháng"
                    options={listMonth}
                  />
                </div>
                <div className="md:w-1/3 w-full px-2">
                  <DropDown
                    value={dob.year}
                    onChange={(value) => {
                      setdob({ ...dob, year: value });
                    }}
                    title="Năm"
                    options={listYear}
                  />
                </div>
              </div>

              <div className="mb-4 font-bold">
                <label className="block text-white text-md font-bold mb-2 border-b">
                  Các kênh cá nhân
                  <a target="_blank" href={instructionMetap}>
                    {" - Xem hướng dẫn"}
                  </a>
                </label>
                <DropDown
                  value={mediaCur}
                  onChange={(value) => {
                    setmediaCur(value);
                    // if (
                    //   mediaChannels.findIndex(
                    //     (i) => i.name === value.value.value
                    //   ) < 0
                    // ) {
                    console.log(value);
                    if (value.name === "Phone") {
                      setmediaChannels([
                        ...mediaChannels,
                        { name: value.value.value, value: "", title: "Phone" },
                      ]);
                    } else if (value.name === "Website") {
                      setmediaChannels([
                        ...mediaChannels,
                        {
                          name: value.value.value,
                          value: "",
                          title: "Website",
                        },
                      ]);
                    } else {
                      setmediaChannels([
                        ...mediaChannels,
                        { name: value.value.value, value: "" },
                      ]);
                    }
                    // }
                  }}
                  title="Chọn kênh cá nhân"
                  options={Object.keys(channelMediaListDefault).map((i) => {
                    return {
                      value: { ...channelMediaListDefault[i], value: i },
                      name: channelMediaListDefault[i].title,
                    };
                  })}
                />
              </div>
              {mediaChannels.length > 1 ? (
                <div className="flex items-center justify-end pb-2">
                  <button
                    className="border border-dashed border-2 font-bold py-1 px-4 rounded focus:outline-none md:w-32 w-full"
                    style={{ minWidth: 40 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setsortChannels(!sortChannels);
                    }}
                  >
                    {sortChannels ? "Xác nhận" : "Sắp xếp"}
                  </button>
                </div>
              ) : null}

              {sortChannels ? (
                <div className="pt-4">
                  <SortableContainer onSortEnd={onSortEnd}>
                    {mediaChannels.map((value, index) => {
                      return (
                        <SortableItem
                          sortChannels={sortChannels}
                          mediaChannelsItem={mediaChannels}
                          key={"item" + index + value.name}
                          index={index}
                          value={value}
                          idx={index}
                        />
                      );
                    })}
                  </SortableContainer>
                </div>
              ) : (
                mediaChannels.map((i, idx) => (
                  <div key={idx} className="mb-5">
                    <div>
                      <MediaChannel
                        {...channelMediaListDefault[i.name]}
                        item={mediaChannels[idx]}
                        onChangeInput={(value) => {
                          let tmp = mediaChannels.map((i) => {
                            return {
                              name: i.name,
                              value: i.value,
                              title: i.title,
                            };
                          });
                          tmp[idx].value = value;
                          setmediaChannels(tmp);
                        }}
                        onChangeInputTitle={(value) => {
                          let tmp = mediaChannels.map((i) => {
                            return {
                              name: i.name,
                              value: i.value,
                              title: i.title,
                            };
                          });
                          tmp[idx].title = value;
                          setmediaChannels(tmp);
                        }}
                        sortChannels={sortChannels}
                        onClick={(e) => {
                          onRemove(idx);
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
              <div className="flex items-center justify-between pt-4">
                <button
                  className="btn-primary font-bold py-2 px-4 rounded focus:outline-none md:w-64 w-full"
                  type="submit"
                  style={{ minWidth: 100 }}
                  onClick={onUpdate}
                >
                  Xác nhận thay đổi
                </button>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 w-full md:px-20 px-2 md:block hidden">
            <div
              className="border-white border-5 relative flex flex-col justify-between"
              style={{ minHeight: 450 }}
            >
              <div>
                <Preview
                  images={images}
                  fullName={fullName}
                  bio={bio}
                  mediaChannels={mediaChannels}
                />
              </div>
              <div className="pt-5 pb-1">
                <div className="flex justify-center">
                  {/* <img
                    className=""
                    src={logoMetap}
                    style={{ width: 205, maxHeight: 70 }}
                    alt="logo"
                  /> */}
                  <div
                    className="font-bold text-5xl text-white px-8 "
                    style={{ letterSpacing: -5, lineHeight: 1 }}
                  >
                    metap
                  </div>
                </div>
                <div className="text-center py-2 px-2 font-semibold text-sm">
                  Thẻ thông minh{" "}
                  <a
                    className=""
                    style={{ color: "#38d39f" }}
                    target="_blank"
                    href="https://metap.vn"
                  >
                    Metap
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center pt-4 text-xs">
              <div>
                Xem trang của bạn tại{" "}
                <a
                  target="_blank"
                  href={"http://metap.vn/" + currentUser?.username}
                >
                  {"http://metap.vn/" + currentUser?.username}
                </a>
              </div>
              Hoặc
              <div>
                Xem trang của bạn tại{" "}
                <a
                  target="_blank"
                  href={"http://metap.vn/" + currentUser?.serialNumber}
                >
                  {"http://metap.vn/" + currentUser?.serialNumber}
                </a>
              </div>
              <br />
              <div>
                <a target="_blank" href={instructionMetap}>
                  {"Xem hướng dẫn"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  isLogin: makeSelectIsLogin(),
  currentUser: makeSelectUser(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    logOut: () => dispatch(logOut()),
  };
};

const StartPageWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default StartPageWithConnect;
