import React from "react";
import Routes from "./Routes";
import { Provider } from "react-redux";
import configureStore from "./configureStore";

import "react-toastify/dist/ReactToastify.css";
import "./index.css"; //Tailwind
import "./tailwind.css";
import "./static/styles/index.scss";

const initialState = {};
export const store = configureStore(initialState);

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}
export default App;
