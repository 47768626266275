import {
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_FAIL,
  LOG_OUT,
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_FAIL,
  FETCH_CURRENT_USER_SUCCESS,
  SET_ERROR,
  CREATE_ACCOUNT,
} from "./constants";

export function logIn(data) {
  return { type: LOG_IN, payload: { data } };
}
export function createAccount(data, resolve, reject) {
  return { type: CREATE_ACCOUNT, payload: { data }, resolve, reject };
}

export function logInSuccess(data) {
  return {
    type: LOG_IN_SUCCESS,
    payload: { data: data.data },
  };
}

export function logInFail(error) {
  return { type: LOG_IN_FAIL, error };
}

export function setError(value) {
  return { type: SET_ERROR, value };
}

export function logOut() {
  return { type: LOG_OUT };
}

export function fetchUser() {
  return { type: FETCH_CURRENT_USER };
}

export function fetchUserFail(error) {
  return { type: FETCH_CURRENT_USER_FAIL, payload: { error } };
}

export function fetchUserSuccess(user) {
  return { type: FETCH_CURRENT_USER_SUCCESS, user };
}
