import { Link, navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import loGet from "lodash/get";
import Icon from "../../../components/Icons";
import { coreAPI } from "../../../utils/request";
import { toast } from "react-toastify";

const notifySuccess = (msg) =>
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export default function ForgotPassword({ location }) {
  const [error, seterror] = useState(null);
  const [password, setpassword] = useState("");

  const onSend = async (e) => {
    e.preventDefault();
    if (!password) {
      return seterror("Vui lòng nhập password");
    }
    try {
      const pathname = location?.pathname.split("/");

      await coreAPI.post("/auth/reset-password", {
        password,
        token: pathname[2],
      });
      notifySuccess("Updated successfull");
      return navigate("/login");
    } catch (error) {
      console.log(error);
      seterror(loGet(error, "response.data.message"));
    }
  };

  return (
    <div
      className="min-h-screen flex justify-center items-center p-4"
      style={{ backgroundColor: "#181818" }}
    >
      <div className="w-full max-w-md">
        <form
          className="bg-white shadow-md rounded p-8 mb-4"
          style={{ backgroundColor: "#232323" }}
          onSubmit={onSend}
        >
          <Icon
            type="avatar"
            style={{ height: 100, width: "auto", margin: "0 auto" }}
          />
          <h2 className="font-bold text-4xl text-center py-4 text-white">
            Đổi Mật Khẩu
          </h2>
          <div className="mb-2">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mật khẩu mới
            </label>
            <input
              value={password}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none"
              id="password"
              type="password"
              placeholder="****"
              onChange={(e) => setpassword(e.target.value)}
            />
            <p className="text-red-500 text-xs italic" style={{ height: 18 }}>
              {error ? error : ""}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="btn-primary font-bold py-2 px-4 rounded focus:outline-none"
              type="submit"
              style={{ minWidth: 100 }}
            >
              Xác nhận
            </button>
            <Link
              className="inline-block align-baseline font-bold text-sm"
              to="/login"
            >
              Đăng nhập?
            </Link>
          </div>
        </form>
        <p className="text-center text-white text-xs">
          &copy;2020 metap.vn - All rights reserved.
        </p>
      </div>
    </div>
  );
}
