import { put, call, takeLatest } from "redux-saga/effects";
import loGet from "lodash/get";
import { CREATE_ACCOUNT } from "../constants";
import { logInSuccess, logInFail } from "../actions";
import { coreAPI, setToken } from "../../../utils/request";

export function* docreateAccount({ payload: { data } }) {
  const url = "/auth/register";
  try {
    const resp = yield call(coreAPI.post, url, data);
    yield call(setToken, resp.access_token);

    yield put(logInSuccess({ data: resp.data }));
  } catch (err) {
    yield put(logInFail(loGet(err, "response.data.message")));
  }
}

export default function* watchcreateAccount() {
  yield takeLatest(CREATE_ACCOUNT, docreateAccount);
}
