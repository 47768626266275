import React from "react";

import { ReactComponent as Avatar } from "../../static/icons/avatar.svg";
import { ReactComponent as Facebook } from "../../static/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../static/icons/instagram.svg";
import { ReactComponent as FacebookF } from "../../static/icons/facebook-f.svg";
import { ReactComponent as Tick } from "../../static/icons/tick.svg";
import { ReactComponent as Times } from "../../static/icons/times.svg";
import { ReactComponent as whatsapp } from "../../static/icons/whatsapp.svg";
import { ReactComponent as zalo } from "../../static/icons/zalo.svg";
import { ReactComponent as youtube } from "../../static/icons/youtube.svg";
import { ReactComponent as envelope } from "../../static/icons/envelope.svg";
import { ReactComponent as phone } from "../../static/icons/phone.svg";
import { ReactComponent as linkedinIn } from "../../static/icons/linkedin-in.svg";
import { ReactComponent as tiktok } from "../../static/icons/tiktok.svg";
import { ReactComponent as tiktokColor } from "../../static/icons/tiktok-color.svg";
import { ReactComponent as snapchat } from "../../static/icons/snapchat.svg";
import { ReactComponent as soundcloud } from "../../static/icons/soundcloud.svg";
import { ReactComponent as twitter } from "../../static/icons/twitter.svg";
import { ReactComponent as spotify } from "../../static/icons/spotify.svg";
import { ReactComponent as globe } from "../../static/icons/globe.svg";
import { ReactComponent as link } from "../../static/icons/link.svg";
import { ReactComponent as stickyNote } from "../../static/icons/sticky-note.svg";
import { ReactComponent as github } from "../../static/icons/github-alt.svg";
import { ReactComponent as momo } from "../../static/icons/momo.svg";
import { ReactComponent as telegram } from "../../static/icons/telegram-plane.svg";
import { ReactComponent as vimeo } from "../../static/icons/vimeo-v.svg";
import { ReactComponent as kakaotalk } from "../../static/icons/kakao-talk.svg";
import { ReactComponent as flickr } from "../../static/icons/flickr.svg";

const icons = {
  tick: Tick,
  avatar: Avatar,
  facebook: Facebook,
  instagram: Instagram,
  times: Times,
  whatsapp: whatsapp,
  zalo: zalo,
  youtube: youtube,
  envelope: envelope,
  phone: phone,
  linkedinIn: linkedinIn,
  tiktok: tiktok,
  tiktokColor: tiktokColor,
  snapchat: snapchat,
  "facebook-f": FacebookF,
  soundcloud: soundcloud,
  twitter: twitter,
  spotify: spotify,
  globe: globe,
  link: link,
  github: github,
  telegram: telegram,
  momo: momo,
  vimeo: vimeo,
  kakaotalk: kakaotalk,
  flickr: flickr,
  "sticky-note": stickyNote,
};

export default function Icon({ type, ...rest }) {
  const Component = icons[type];

  if (!Component) {
    console.error(
      `The icon with type:${type} doesn't exist. Make sure you pass the existing one.`
    );
    return null;
  }
  return <Component fill="currentColor" {...rest} />;
}
