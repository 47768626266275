import React, { Component } from "react";
import { render } from "react-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => <span>::</span>);

const SortableItem = sortableElement(({ value }) => (
  <li>
    <DragHandle />
    {value}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

export default class App extends Component {
  state = {
    items: ["Item 1123123", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const itemtmp = [...this.state.items];
    const tmp = itemtmp[oldIndex];
    itemtmp[oldIndex] = itemtmp[newIndex];
    itemtmp[newIndex] = tmp;
    this.setState(({ items }) => ({
      items: itemtmp,
    }));
  };

  render() {
    const { items } = this.state;

    return (
      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </SortableContainer>
    );
  }
}
