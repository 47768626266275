import { combineReducers } from "redux";
import authReducer from "./containers/Auth/reducer";

export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    auth: authReducer,
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    return appReducer(state, action);
  };

  return rootReducer;
}
