import { all } from "redux-saga/effects";
import watchLogin from "./sagas/watchLogin";
import watchLogout from "./sagas/watchLogout";
import watchFetchUser from "./sagas/watchFetchUser";
import watchCreateAccount from "./sagas/watchCreateAccount";

export default function* watchAll() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchFetchUser(),
    watchCreateAccount(),
  ]);
}
